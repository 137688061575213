import { createRouter, createWebHistory } from 'vue-router'
import VueNprogress from 'vue-nprogress'

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views/LandingPage.vue')
  },
  {
    path: '/wait-list',
    name: 'waitList',
    component: () => import('@/views/LandingPage.vue'),
    props: () => ({  checkWaitList: true })
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export const nprogress = new VueNprogress({
  showSpinner: false
})

router.beforeEach(() => {
  nprogress.start()
})

router.afterEach(() => {
  nprogress.done()
})

export default router
